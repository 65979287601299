<template><div id="socketio"></div></template>

<script>
import SocketIO from "@/socketio";
import { getUserData, logoutUserRedirectToLogin } from "@/auth/utils";

export default {
  data() {
    return {
      userLogged: null,
      SocketIOConnectError: "connect_error",
      SocketIOPrivateMessage: "private message",
    };
  },
  beforeMount() {
      this.userLogged = {
        id_usuario: getUserData().usuario.id_usuario,
        email: getUserData().usuario.email,
        access_token: getUserData().auth.access_token,
        id_colaborador: getUserData().colaborador?.id_colaborador ?? null
      };

      this.consultaMinhasNotificacoes();
      this.connect();
  },
  methods: {
    connect() {
      SocketIO.auth = this.userLogged;
      this.$store.dispatch(
        "notifications/setUser",
        {
          id_usuario: this.userLogged.id_usuario,
          id_colaborador: this.userLogged.id_colaborador
        }
      );
      if(this.layoutIsNotCora()) {
        SocketIO.connect();
      }
    },
    disconnectSocket() {
      SocketIO.disconnect();
    },
    consultaMinhasNotificacoes() {
      if(this.layoutIsNotCora()) {
        this.$http.get(this.$api.MinhasNotificacoesPendentes).then((res) => {
          this.$store.dispatch(
            "notifications/setItens",
            res.status == 204 ? [] : res.data
          );
        });
      }
    },
    layoutIsNotCora(){
      const path = window.location.pathname;
      const firstSegment = path.split('/')[1];
  
      return (firstSegment !== 'cora' && firstSegment !== 'cora-aplicacoes');
    },
  },
  created() {
    SocketIO.on(this.SocketIOPrivateMessage, (objeto) => {
      if(objeto.content === 'logout' || objeto.content === 'auto_logout'){
        logoutUserRedirectToLogin();
      }else{
        this.consultaMinhasNotificacoes()
      }
    });
  },
  beforeDestroy() {
    this.disconnectSocket();
  },
};
</script>
